@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  background-color: #0a1b10; /* Dark green background for a similar feel */
  color: #f2f2f2; /* Soft white color for text */
  font-family: 'Inter', sans-serif;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  flex-direction: column; /* Aligning items vertically */
}

.text-container {
  max-width: 700px;
  padding: 50px;
  text-align: center;
}

.text-line {
  margin: 0;
  padding: 1rem 0;
  font-size: 1.75rem; /* Larger font for emphasis */
  font-weight: 400;
  letter-spacing: 1px; /* Slight letter spacing */
}

.text-line strong {
  font-weight: 700; /* Bold for emphasis */
}

a {
  color: #f2f2f2;
  text-decoration: none;
  border-bottom: 2px solid #f2f2f2; /* Underline effect similar to the image */
}

a:hover {
  color: #d15f5f; /* Subtle color change on hover */
  border-bottom-color: #d15f5f; /* Matching underline color change */
}

.footer-brother {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #0a1b10; /* Match background */
}

.large-brother-text {
  font-size: 60px; /* Still large but more modest */
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #d15f5f; /* Muted red tone for contrast */
  letter-spacing: 3px;
}

.large-brother-text a {
  color: #d15f5f; /* Matching text color for links */
}

.large-brother-text a:hover {
  color: #f2f2f2; /* Reverse effect on hover */
}
